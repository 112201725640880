@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background: rgb(111, 168, 142);
  background: linear-gradient(
    90deg,
    rgba(111, 168, 142, 1) 0%,
    rgba(98, 146, 157, 1) 100%
  );
  background-repeat: no-repeat;

  background-position: center center;
  background-attachment: fixed;

  background-size: cover;
}
.e-mployed-dashboard {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.e-mployed-dashboard .learning-wrapper {
  margin: 0; /* Remove any default margins */
  padding: 0; /* Remove any default padding */
}

.nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  gap: 0rem;
  padding-block: 10rem;
}
.nav-list li {
  padding: 1.5rem 2.5rem;
  margin-left: 1rem;
  border-radius: 100vw 0 0 100vw;
}
.nav-list li.active {
  -webkit-backdrop-filter: blur(60px) brightness(100%);
  backdrop-filter: blur(60px) brightness(100%);
  background-color: #c8dcda;
  position: relative;
}
.nav-list li span {
  display: flex;
  align-items: center;

  color: #fff;
  text-decoration: none;
}
.nav-list li.active::before,
.nav-list li.active::after {
  --border-radius: 1.5rem;

  content: "";
  position: absolute;

  width: var(--border-radius);
  height: var(--border-radius);
  right: 0;
  background: var(--nav-bg);
}
.nav-list li.active::before {
  top: calc(var(--border-radius) * -1);
  border-radius: 0 0 100vw 0;

  box-shadow: 5px 5px 0 5px #c1d7d8;
}
.nav-list li.active::after {
  bottom: calc(var(--border-radius) * -1);
  border-radius: 0 100vw 0 0;

  box-shadow: 5px -5px 0 5px #c1d7d8;
}

.nav-list li span.active {
  color: #242730;
}

.nav-list2 {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  gap: 0rem;
  margin-top: -100px;
  padding-bottom: 20rem;
}
.nav-list2 li {
  padding: 1.5rem 2.5rem;
  margin-left: 1rem;
  border-radius: 100vw 0 0 100vw;
}
.nav-list2 li.active {
  -webkit-backdrop-filter: blur(60px) brightness(100%);
  backdrop-filter: blur(60px) brightness(100%);
  background-color: #c8dcda;
  position: relative;
}
.nav-list2 li span {
  display: flex;
  align-items: center;

  color: #fff;
  text-decoration: none;
}
.nav-list2 li.active::before,
.nav-list2 li.active::after {
  --border-radius: 1.5rem;

  content: "";
  position: absolute;

  width: var(--border-radius);
  height: var(--border-radius);
  right: 0;
  background: var(--nav-bg);
}
.nav-list2 li.active::before {
  top: calc(var(--border-radius) * -1);
  border-radius: 0 0 100vw 0;

  box-shadow: 5px 5px 0 5px #c1d7d8;
}
.nav-list2 li.active::after {
  bottom: calc(var(--border-radius) * -1);
  border-radius: 0 100vw 0 0;

  box-shadow: 5px -5px 0 5px #c1d7d8;
}

.nav-list2 li span.active {
  color: #242730;
}

.e-mployed-dashboard .learning {
  -webkit-backdrop-filter: blur(60px) brightness(100%);
  backdrop-filter: blur(60px) brightness(100%);

  background-color: #c8dcda;

  border-radius: 24px;
  box-shadow: 0px 10px 100px #3e266526;
  height: 700px;
  left: 50%; /* Set left to 50% */
  position: absolute; /* Change position to absolute */
  top: 50%; /* Set top to 50% */
  transform: translate(-50%, -50%); /* Center the element */
  width: 1535px;
  margin: 0 auto; /* Add this line to center horizontally */
}

.e-mployed-dashboard .learning-x {
  height: 700px;
  left: 50%; /* Set left to 50% */
  position: absolute; /* Change position to absolute */
  top: 50%; /* Set top to 50% */
  transform: translate(-50%, -50%); /* Center the element */
  width: 1535px;
  margin: 0 auto; /* Add this line to center horizontally */
}
.e-mployed-dashboard .frame {
  /* existing styles */
  z-index: 2; /* set a higher z-index for the .frame */
}

.e-mployed-dashboard .frame {
  background-color: #71a8a8;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 24px;
  height: 700px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 272px;
}

.e-mployed-dashboard .overlap-group {
  background-color: #24273080;
  background-size: 100% 100%;
  height: 700px;
  position: relative;
}

.e-mployed-dashboard .overlap-group .menu {
  -webkit-backdrop-filter: blur(60px) brightness(100%);
  backdrop-filter: blur(60px) brightness(100%);
  background-color: #ffffff99;
  padding: 1rem 2rem;
  border-radius: 100vw 0 0 100vw;
}

.e-mployed-dashboard .menu {
  align-items: center;
  display: flex;
  gap: 8px;
  left: 72px;
  position: absolute;
  top: 188px;
  width: 200px;
}

.e-mployed-dashboard .home {
  height: 22px;
  object-fit: cover;
  position: relative;
  width: 22px;
}

.e-mployed-dashboard .text-wrapper {
  color: #24262f;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.e-mployed-dashboard .div {
  align-items: center;
  display: flex;
  gap: 8px;
  left: 72px;
  position: absolute;
  top: 252px;
  width: 150px;
}

.e-mployed-dashboard .book {
  height: 24px;
  position: relative;
  width: 24px;
}

.e-mployed-dashboard .text-wrapper-2 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.6px;
  margin-right: -4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.e-mployed-dashboard .menu-2 {
  align-items: center;
  display: flex;
  gap: 8px;
  left: 72px;
  position: absolute;
  top: 318px;
  width: 177px;
}

.e-mployed-dashboard .pen {
  height: 24px;
  object-fit: cover;
  position: relative;
  width: 24px;
}

.e-mployed-dashboard .text-wrapper-3 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.e-mployed-dashboard .rectangle {
  border-radius: 2px;
  height: 1px;
  left: 72px;
  position: absolute;
  top: 384px;
  width: 160px;
}

.e-mployed-dashboard .menu-3 {
  align-items: center;
  display: flex;
  gap: 8px;
  left: 72px;
  position: absolute;
  top: 427px;
  width: 162px;
}

.e-mployed-dashboard .menu-4 {
  align-items: center;
  display: flex;
  gap: 8px;
  left: 72px;
  position: absolute;
  top: 491px;
  width: 155px;
}

.e-mployed-dashboard .subtract {
  height: 21px;
  left: 5px;
  position: absolute;
  top: 2px;
  width: 14px;
}

.e-mployed-dashboard .overlap {
  height: 104px;
  left: 19px;
  position: absolute;
  top: 32px;
  width: 234px;
}

.e-mployed-dashboard .img {
  height: 87px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 234px;
}

.e-mployed-dashboard .logo {
  height: 104px;
  left: 4px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 227px;
}

.e-mployed-dashboard .div-wrapper {
  align-items: center;
  background-color: #60d48e;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  height: 43px;
  justify-content: center;
  left: 41px;
  padding: 7px 16px;
  position: absolute;
  top: 458px;
  width: 190px;
}

.e-mployed-dashboard .text-wrapper-4 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.6px;
  margin-top: -1.5px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.e-mployed-dashboard .overlap-2 {
  height: 689px;
  left: 331px;
  position: absolute;
  top: 11px;
  width: 1093px;
}

.e-mployed-dashboard .overlap-3 {
  height: 689px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1093px;
}
.frame-2::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.e-mployed-dashboard .frame-2 {
  align-items: flex-start;
  border-radius: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* Adjust the gap between cards as needed */
  overflow-y: auto; /* Enable vertical scrolling */

  display: flex;
  gap: 25px 25px;
  height: 630px;
  left: 548px;
  overflow: hidden;
  overflow-y: scroll;
  position: absolute;
  top: 59px;
  width: 518px;
}

.e-mployed-dashboard .cardhome {
  background: linear-gradient(
    180deg,
    rgba(223, 119, 98, 0.62) 0%,
    rgba(116, 180, 142, 0.56) 100%
  );
  border-radius: 24px;

  position: relative;
  width: 240px;

  overflow: hidden;

  /* Ensure text doesn't overflow */
  white-space: nowrap;
  text-overflow: ellipsis;
}

.e-mployed-dashboard .overlap-group-2 {
  height: 180px;
  position: relative;
}

.e-mployed-dashboard .frame-3 {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  left: 0;
  padding: 8px 16px 16px;
  position: absolute;
  top: 0;
  width: 240px;
}

.e-mployed-dashboard .text-wrapper-5 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 700;
  height: 25px;
  letter-spacing: 0;
  line-height: 25.2px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 205px;
}

.e-mployed-dashboard .rectangle-2home {
  top: 80px;
  position: sticky;
  margin-left: 15px;
  width: 200px;
}

.e-mployed-dashboard .overlap-wrapper {
  background: linear-gradient(
    180deg,
    rgb(113, 157, 214) 0%,
    rgba(116, 180, 142, 0.5) 100%
  );
  border-radius: 24px;
  height: 180px;
  position: relative;
  width: 240px;
}

.e-mployed-dashboard .rectangle-3 {
  height: 160px;
  left: 34px;
  object-fit: cover;
  position: absolute;
  top: 20px;
  width: 171px;
}

.e-mployed-dashboard .overlap-group-wrapper {
  background: linear-gradient(
    180deg,
    rgb(223, 119, 98) 0%,
    rgba(113, 157, 214, 0.52) 100%
  );
  border-radius: 24px;
  height: 180px;
  position: relative;
  width: 240px;
}

.e-mployed-dashboard .rectangle-4 {
  height: 180px;
  left: 30px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 191px;
}

.e-mployed-dashboard .card-2 {
  background: linear-gradient(
    180deg,
    rgb(116, 180, 142) 0%,
    rgba(113, 157, 214, 0.5) 100%
  );
  border-radius: 24px;
  height: 180px;
  position: relative;
  width: 240px;
}

.e-mployed-dashboard .card-3 {
  background: linear-gradient(
    180deg,
    rgba(116, 180, 142, 0.62) 0%,
    rgba(223, 119, 98, 0.56) 100%
  );
  border-radius: 24px;
  height: 180px;
  position: relative;
  width: 240px;
}

.e-mployed-dashboard .rectangle-5 {
  height: 147px;
  left: 42px;
  object-fit: cover;
  position: absolute;
  top: 33px;
  width: 155px;
}

.e-mployed-dashboard .rectangle-6 {
  height: 141px;
  left: 24px;
  object-fit: cover;
  position: absolute;
  top: 39px;
  width: 175px;
}

.e-mployed-dashboard .rectangle-7 {
  height: 167px;
  left: 30px;
  object-fit: cover;
  position: absolute;
  top: 13px;
  width: 177px;
}

.e-mployed-dashboard .card-4 {
  background: linear-gradient(
    180deg,
    rgb(223, 119, 98) 0%,
    rgba(113, 157, 214, 0.52) 100%
  );
  border-radius: 24px;
  box-shadow: var(--shadow-violet);
  height: 180px;
  position: relative;
  width: 240px;
}

.e-mployed-dashboard .rectangle-8 {
  height: 172px;
  left: 28px;
  object-fit: cover;
  position: absolute;
  top: 8px;
  width: 181px;
}

.e-mployed-dashboard .rectangle-9 {
  height: 147px;
  left: 31px;
  object-fit: cover;
  position: absolute;
  top: 33px;
  width: 178px;
}

.e-mployed-dashboard .profile-card {
  height: 248px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 655px;
}

.e-mployed-dashboard .overlap-4 {
  height: 175px;
  left: -7px;
  position: absolute;
  top: 62px;
  width: 441px;
}

.e-mployed-dashboard .rectangle-10 {
  -webkit-backdrop-filter: blur(26.14px) brightness(100%);
  backdrop-filter: blur(26.14px) brightness(100%);
  background-color: #ffffff66;
  border-radius: 31.37px;
  box-shadow: 13.07px 13.07px 104.57px #3e26651a;
  height: 157px;
  left: 7px;
  position: absolute;
  top: 7px;
  width: 386px;
}

.e-mployed-dashboard .rectangle-11 {
  background-color: #0000001a;
  border-radius: 2.61px;
  height: 1px;
  left: 129px;
  position: absolute;
  top: 122px;
  transform: rotate(90deg);
  width: 58px;
}

.e-mployed-dashboard .frame-4 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5.23px;
  height: 88px;
  justify-content: center;
  left: 16px;
  padding: 26.14px 26.14px 13.07px;
  position: absolute;
  top: 0;
  width: 284px;
}

.e-mployed-dashboard .text-wrapper-6 {
  align-self: stretch;
  color: #264e65;
  font-family: "Poppins", Helvetica;
  font-size: 18.3px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25.6px;
  margin-top: -5.53px;
  position: relative;
  text-align: center;
}

.e-mployed-dashboard .text-wrapper-7 {
  align-self: stretch;
  color: #959aa5;
  font-family: "Poppins", Helvetica;
  font-size: 16.3px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25.6px;
  margin-bottom: -2.91px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.e-mployed-dashboard .check {
  height: 24px;
  left: 269px;
  position: absolute;
  top: 54px;
  width: 24px;
}

.e-mployed-dashboard .frame-5 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5.23px;
  height: 89px;
  justify-content: center;
  left: 0;
  padding: 13.07px 26.14px 26.14px;
  position: absolute;
  top: 86px;
  width: 157px;
}

.e-mployed-dashboard .text-wrapper-8 {
  color: #959aa5;
  font-family: "Poppins", Helvetica;
  font-size: 15.7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: -10.03px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.e-mployed-dashboard .text-wrapper-9 {
  color: #264e65;
  font-family: "Poppins", Helvetica;
  font-size: 28.8px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 40.3px;
  margin-bottom: -7.41px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.e-mployed-dashboard .frame-6 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5.23px;
  height: 89px;
  justify-content: center;
  left: 157px;
  padding: 13.07px 26.14px 26.14px;
  position: absolute;
  top: 86px;
  width: 157px;
}

.e-mployed-dashboard .avatar {
  background-position: 50% 50%;
  background-size: cover;
  border: 5.23px solid;
  border-color: #ffffff;
  border-radius: 65.35px;
  height: 112px;
  left: 329px;
  position: absolute;
  top: 30px;
  width: 112px;
}

.e-mployed-dashboard .text-wrapper-10 {
  color: #264e65;
  font-family: "Poppins", Helvetica;
  font-size: 22px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 30.8px;
  position: absolute;
  top: 245px;
  white-space: nowrap;
  width: 132px;
}

.e-mployed-dashboard .text-wrapper-11 {
  color: #264e65;
  font-family: "Poppins", Helvetica;
  font-size: 22px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 30.8px;
  position: absolute;
  top: 26px;
  white-space: nowrap;
  width: 269px;
}

.e-mployed-dashboard .text-wrapper-12 {
  color: #264e65;
  font-family: "Poppins", Helvetica;
  font-size: 22px;
  font-weight: 700;
  left: 571px;
  letter-spacing: 0;
  line-height: 30.8px;
  position: absolute;
  top: 24px;
  white-space: nowrap;
  width: 195px;
}

.e-mployed-dashboard .notification {
  background-color: #2b303980;
  background-image: url(https://c.animaapp.com/3fc6Cqeo/img/notification.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 24px;
  height: 139px;
  left: 579px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 514px;
}

.e-mployed-dashboard .short-description {
  -webkit-text-stroke: 1px #000000;
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 11px;
  font-weight: 400;
  left: 70px;
  letter-spacing: 0;
  line-height: 15.4px;
  position: absolute;
  top: 13px;
  width: 415px;
}

.e-mployed-dashboard .bell {
  height: 87px;
  left: 14px;
  position: absolute;
  top: 26px;
  width: 46px;
}

.e-mployed-dashboard .frame-7 {
  background-color: #ffffff;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 24px;
  box-shadow: var(--shadow-violet);
  height: 374px;
  left: 0px; /* Modificare aici */
  position: absolute;
  top: 291px;
  width: 488px;
}
.e-mployed-dashboard .frame-7 .content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0px;
  box-sizing: border-box;
}
@media (min-width: 300px) and (max-width: 999px) {
  /* Further adjustments for even smaller screens */
  .e-mployed-dashboard .learning {
    max-width: 100%;
    max-height: 100%;
  }
  .e-mployed-dashboard .frame {
    z-index: 0;
  }
  .e-mployed-dashboard .overlap-2 {
    left: 0;
  }
}
@media (min-width: 1000px) and (max-width: 1180px) {
  /* Further adjustments for even smaller screens */
  .e-mployed-dashboard .learning {
    width: 95%; /* Adjust width */
    max-width: 95%;
  }

  .e-mployed-dashboard .overlap-2 {
    left: 268px;
  }
}
@media (min-width: 1181px) and (max-width: 1280px) {
  /* Further adjustments for even smaller screens */
  .e-mployed-dashboard .learning {
    width: 95%; /* Adjust width */
    max-width: 95%;
  }

  .e-mployed-dashboard .frame-2 {
    align-items: flex-start;
    border-radius: 24px;

    flex-wrap: wrap;
    gap: 16px; /* Adjust the gap between cards as needed */
    overflow-y: auto; /* Enable vertical scrolling */
    display: grid;
    gap: 25px 25px;
    height: 630px;
    left: 548px;
    overflow: hidden;
    overflow-y: scroll;
    position: absolute;
    top: 59px;
    width: 518px;
  }
}
@media (max-width: 1281px) and (max-width: 1380px) {
  /* Further adjustments for even smaller screens */
  .e-mployed-dashboard .learning {
    width: 95%; /* Adjust width */
    max-width: 95%;
    background-repeat: no-repeat;

    background-position: center center;
    background-attachment: fixed;

    background-size: cover;
  }

  .e-mployed-dashboard .frame-2 {
    align-items: flex-start;
    border-radius: 24px;

    flex-wrap: wrap;
    gap: 16px; /* Adjust the gap between cards as needed */
    overflow-y: auto; /* Enable vertical scrolling */
    display: grid;
    gap: 25px 25px;
    height: 630px;
    left: 548px;
    overflow: hidden;
    overflow-y: scroll;
    position: absolute;
    top: 59px;
    width: 518px;
  }
}
@media (min-width: 1381px) and (max-width: 1480px) {
  /* Further adjustments for even smaller screens */
  .e-mployed-dashboard .learning {
    width: 95%; /* Adjust width */
    max-width: 95%;
    background-repeat: no-repeat;

    background-position: center center;
    background-attachment: fixed;

    background-size: cover;
  }

  .e-mployed-dashboard .frame-2 {
    align-items: flex-start;
    border-radius: 24px;

    flex-wrap: wrap;
    gap: 16px; /* Adjust the gap between cards as needed */
    overflow-y: auto; /* Enable vertical scrolling */
    display: grid;
    gap: 25px 25px;
    height: 630px;
    left: 548px;
    overflow: hidden;
    overflow-y: scroll;
    position: absolute;
    top: 59px;
    width: 518px;
  }
}
@media (min-width: 1481px) and (max-width: 1580px) {
  /* Further adjustments for even smaller screens */
  .e-mployed-dashboard .learning {
    width: 95%; /* Adjust width */
    max-width: 95%;
    background-repeat: no-repeat;

    background-position: center center;
    background-attachment: fixed;

    background-size: cover;
  }

  .e-mployed-dashboard .frame-2 {
    align-items: flex-start;
    border-radius: 24px;

    flex-wrap: wrap;
    gap: 16px; /* Adjust the gap between cards as needed */
    overflow-y: auto; /* Enable vertical scrolling */
    display: flex;
    gap: 25px 25px;
    height: 630px;
    left: 548px;
    overflow: hidden;
    overflow-y: scroll;
    position: absolute;
    top: 59px;
    width: 518px;
  }
}

@media (min-width: 400px) and (max-width: 1180px) {
  .e-mployed-dashboard .learning {
    width: 95%; /* Adjust width */
    max-width: 95%;
    background-repeat: no-repeat;

    background-position: center center;
    background-attachment: fixed;

    background-size: cover;
  }

  .e-mployed-dashboard .frame-2 {
    align-items: flex-start;
    border-radius: 24px;

    flex-wrap: wrap;
    gap: 16px; /* Adjust the gap between cards as needed */
    overflow-y: auto; /* Enable vertical scrolling */
    display: grid;
    gap: 25px 25px;
    height: 600px;
    left: 528px;
    overflow: hidden;
    overflow-y: scroll;
    position: absolute;
    top: 59px;
    width: 518px;
  }
  .e-mployed-dashboard .profile-card {
    left: 30px;
  }
  .e-mployed-dashboard .frame-7 {
    left: 30px;
  }
}
