.cardcha {
  background: linear-gradient(180deg,
      rgba(113, 157, 214, 0.85) 0%,
      rgba(116, 180, 142, 0.85) 100%);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 24px;
  height: 500px;
  left: 331px;
  position: relative;
  top: 76px;
  width: 992px;
}

.cardcha .text-wrapper {
  color: #4b696b;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  height: 35px;
  left: 37px;
  letter-spacing: 0;
  line-height: 33.6px;
  position: absolute;
  top: 40px;
  white-space: nowrap;
  width: 344px;
}

.cardcha .div {
  color: #ffffff;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  height: 35px;
  left: 37px;
  letter-spacing: 0;
  line-height: 33.6px;
  position: absolute;
  top: 90px;
  white-space: nowrap;
  width: 872px;
}

.cardstar {
  list-style-type: none;
  background-color: #ffffff40;
  border-radius: 24px;
  overflow: hidden;
}

.cardstar>input {
  display: none;
}

.cardstar1>input {
  display: none;
}

.cardstar2>input {
  display: none;
}

.scroll {
  overflow: scroll;
  height: 500px;
}

::-webkit-scrollbar {
  display: none;
}

.cardstar .text-wrapper {
  color: #ffffff;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 32px;
  font-weight: 700;
  height: 25px;
  left: 70px;
  letter-spacing: 0;
  line-height: 44.8px;
  position: absolute;
  text-align: center;
  top: 45px;
  white-space: nowrap;
  width: 145px;
}

.cardstar .star {
  height: 138px;
  left: 73px;
  object-fit: cover;
  position: absolute;
  top: 109px;
  width: 138px;
}

.cardstar1 {
  background-color: #ffffff40;
  border-radius: 24px;
  height: 281px;
  left: 350px;
  overflow: hidden;
  position: relative;
  width: 284px;
  top: -180px;
}

.cardstar1 .text-wrapper {
  color: #ffffff;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 32px;
  font-weight: 700;
  height: 25px;
  left: 70px;
  letter-spacing: 0;
  line-height: 44.8px;
  position: absolute;
  text-align: center;
  top: 45px;
  white-space: nowrap;
  width: 145px;
}

.cardstar1 .star {
  height: 138px;
  left: 73px;
  object-fit: cover;
  position: absolute;
  top: 109px;
  width: 138px;
}

.cardstar1 .star22 {
  height: 138px;
  left: 123px;
  object-fit: cover;
  position: absolute;
  top: 109px;
  width: 138px;
}

.cardstar1 .star2 {
  height: 138px;
  left: 13px;
  object-fit: cover;
  position: absolute;
  top: 109px;
  width: 138px;
}

.cardstar2 {
  background-color: #ffffff40;
  border-radius: 24px;
  height: 281px;
  left: 690px;
  overflow: hidden;
  position: relative;
  width: 284px;
  top: -460px;
}

.cardstar2 .text-wrapper {
  color: #ffffff;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 32px;
  font-weight: 700;
  height: 25px;
  left: 70px;
  letter-spacing: 0;
  line-height: 44.8px;
  position: absolute;
  text-align: center;
  top: 45px;
  white-space: nowrap;
  width: 145px;
}

.cardstar2 .star322 {
  height: 138px;
  left: 160px;
  object-fit: cover;
  position: absolute;
  top: 109px;
  width: 138px;
}

.cardstar2 .star32 {
  height: 138px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 109px;
  width: 138px;
}

.cardstar2 .star323 {
  height: 138px;
  left: 80px;
  object-fit: cover;
  position: absolute;
  top: 109px;
  width: 138px;
}

.ReactPlayer {
  width: 50%;
  height: 309px;
  left: auto;
  margin-left: 200px;
}