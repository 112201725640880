.course-page .card-cx {
  background: linear-gradient(
    180deg,
    rgba(113, 157, 214, 0.85) 0%,
    rgba(116, 180, 142, 0.85) 100%
  );
  border-radius: 24px;
  height: 500px;
  left: 331px;
  position: absolute;
  top: 76px;
  width: 992px;
}

.course-page .text-wrapper {
  color: #264e65;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  height: 35px;
  left: 37px;
  letter-spacing: 0;
  line-height: 33.6px;
  position: absolute;
  top: 40px;
  white-space: nowrap;
  width: 344px;
}

.course-page .empathy-is-the {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  left: 36px;
  letter-spacing: 0;
  line-height: 190%;
  position: absolute;
  top: 95px;
  width: 919px;
  font-style: normal;
  text-wrap: balance;
}

.course-page .text-wrapper-3 {
  color: #264e65;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 36px;
  font-weight: 700;
  height: 35px;
  left: 331px;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 31px;
  white-space: nowrap;
  width: 455px;
}

.course-page .horizontal-card {
  align-items: center;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: var(--shadow-violet);
  display: flex;
  gap: 16px;
  height: 100px;
  left: 331px;
  padding: 16px;
  position: absolute;
  top: 588px;
  width: 240px;
}

.course-page .text-wrapper-4 {
  color: #24262f;
  font-family: var(--p-14-font-family);
  font-size: var(--p-14-font-size);
  font-style: var(--p-14-font-style);
  font-weight: var(--p-14-font-weight);
  letter-spacing: var(--p-14-letter-spacing);
  line-height: var(--p-14-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.course-page .text-wrapper-5 {
  color: #959aa5;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16.8px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.course-page .horizontal-card-2 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: var(--shadow-violet);
  display: flex;
  gap: 16px;
  height: 100px;
  left: 633px;
  padding: 16px;
  position: absolute;
  top: 588px;
  width: 240px;
}

.course-page .challenge-wrapper {
  border-radius: 16px;
  height: 64px;
  overflow: hidden;
  position: relative;
  width: 64px;
}

.course-page .horizontal-card-3 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: var(--shadow-violet);
  display: flex;
  gap: 16px;
  height: 100px;
  left: 935px;
  padding: 16px;
  position: absolute;
  top: 588px;
  width: 240px;
}

.course-page .completed-wrapper {
  border-radius: 16px;
  height: 64px;
  overflow: hidden;
  position: relative;
  width: 64px;
}

.course-page .quiz-wrapper {
  border-radius: 16px;
  height: 64px;
  overflow: hidden;
  position: relative;
  width: 64px;
}

@media (min-width: 1481px) and (max-width: 1580px) {
  /* Further adjustments for even smaller screens */

  .course-page .card-cx {
    width: 70%;
  }
  .course-page .empathy-is-the {
    font-size: 15px;
  }
}

@media (min-width: 1381px) and (max-width: 1480px) {
  .course-page .card-cx {
    width: 70%;
  }
  .course-page .empathy-is-the {
    font-size: 15px;
  }
}

@media (max-width: 1281px) and (max-width: 1380px) {
  .course-page .card-cx {
    width: 65%;
  }
  .course-page .empathy-is-the {
    font-size: 15px;
    width: 85%;
  }
}
