.response-writer .text-wrapper {
  color: #264e65;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 22px;
  font-weight: 700;
  left: 331px;
  letter-spacing: 0;
  line-height: 30.8px;
  position: absolute;
  top: 42px;
  white-space: nowrap;
  width: 269px;
}

.response-writer .img {
  height: 24px;
  object-fit: cover;
  position: relative;
  width: 24px;
}

.response-writer .frame-2 {
  height: 87px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 234px;
}

.response-writer .logo {
  height: 104px;
  left: 4px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 227px;
}

.response-writer .cardresponse {
  background: linear-gradient(180deg,
      rgba(113, 157, 214, 0.85) 0%,
      rgba(116, 180, 142, 0.85) 100%);
  border-radius: 24px;
  height: 453px;
  left: 331px;
  position: absolute;
  top: 91px;
  width: 992px;
}

.response-writer .title {
  color: #ffffff;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  height: 35px;
  left: 37px;
  letter-spacing: 0;
  line-height: 33.6px;
  position: absolute;
  top: 20px;
  white-space: nowrap;
  width: 939px;
}

.response-writer .message {
  color: #dbdbdb;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  height: 35px;
  left: 37px;
  letter-spacing: 0;
  line-height: 33.6px;
  position: absolute;
  top: 60px;
}

.response-writer .div-wrapper {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 22px;
  display: flex;
  gap: 8px;
  height: 136px;
  left: 37px;
  padding: 10px 20px;
  position: absolute;
  top: 257px;
  width: 932px;
}

.response-writer .div-wrapper1 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 22px;
  display: flex;
  gap: 8px;
  height: 136px;
  left: 37px;
  padding: 10px 20px;
  position: absolute;
  top: 107px;
  width: 932px;
}

.response-writer .text-wrapper-4 {
  color: #959aa5;
  font-family: var(--p-14-font-family);
  font-size: var(--p-14-font-size);
  font-style: var(--p-14-font-style);
  font-weight: var(--p-14-font-weight);
  height: 20px;
  letter-spacing: var(--p-14-letter-spacing);
  line-height: var(--p-14-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 186px;
}

.response-writer .frame-3 {
  background: linear-gradient(180deg,
      rgba(113, 157, 214, 0.85) 0%,
      rgba(116, 180, 142, 0.85) 100%);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  height: 33px;
  justify-content: center;
  left: 37px;
  padding: 7px 16px;
  position: absolute;
  top: 60px;
  width: 194px;
}

.response-writer .text-wrapper-5 {
  color: #ffffff;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.6px;
  margin-top: -1.5px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.response-writer .frame-4 {
  align-items: center;
  background-color: #60d48e;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  height: 33px;
  justify-content: center;
  left: 37px;
  padding: 7px 16px;
  position: absolute;
  top: 402px;
  width: 93px;
}

@media (min-width: 1481px) and (max-width: 1580px) {
  /* Further adjustments for even smaller screens */

  .response-writer .cardresponse {
    width: 70%;
  }

  .response-writer .div-wrapper {
    width: 70%;
  }

  .response-writer .div-wrapper1 {
    width: 70%;
  }

  .response-writer .title {
    width: 70%;
  }

  .response-writer .message {
    width: 70%;
  }
}

@media (min-width: 1381px) and (max-width: 1480px) {
  .response-writer .cardresponse {
    width: 70%;
  }

  .response-writer .div-wrapper {
    width: 70%;
  }

  .response-writer .div-wrapper1 {
    width: 70%;
  }

  .response-writer .title {
    width: 70%;
    font-size: 24px;
  }

  .response-writer .message {
    width: 70%;
    font-size: 24px;
  }
}

@media (max-width: 1281px) and (max-width: 1380px) {
  .response-writer .cardresponse {
    width: 60%;
  }

  .response-writer .div-wrapper {
    width: 70%;
  }

  .response-writer .div-wrapper1 {
    width: 70%;
  }

  .response-writer .title {
    width: 70%;
    font-size: 14px;
  }

  .response-writer .message {
    width: 70%;
    font-size: 14px;
  }
}

@media (max-width: 1181px) and (max-width: 1280px) {
  .response-writer .cardresponse {
    width: 50%;
  }

  .response-writer .div-wrapper {
    width: 70%;
  }

  .response-writer .div-wrapper1 {
    width: 70%;
  }

  .response-writer .title {
    width: 70%;
    font-size: 14px;
  }

  .response-writer .message {
    width: 40%;
    font-size: 14px;
  }
}