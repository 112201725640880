.review-reqests .framex {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: auto;
  overflow: hidden;
  max-height: 540px; /* Modificarea aici pentru a utiliza max-height în loc de height */

  overflow-y: scroll;
  padding: 3px;
  position: relative;
  top: 150px;
  width: 90%;
  left: 350px;
}

.review-reqests .cardreqests {
  background: linear-gradient(
    180deg,
    rgba(113, 157, 214, 0.85) 0%,
    rgba(116, 180, 142, 0.85) 100%
  );

  position: relative;
  border-radius: 34px;
  margin-bottom: 30px; /* Adaugat pentru a crea spatiu intre carduri */
  padding: 20px; /* Adaugat pentru a mari spatiul intern al cardului */
  width: 950px; /* Cardul ocupa 100% din latimea containerului */
  height: 130px;
  min-height: 170px;
}

.review-reqests .miu-adrianreqests {
  color: #ffffff;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 18px;
  left: 43px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 39px;
  white-space: nowrap;
  width: 832px;
}

.review-reqests .text-wrapperreqests {
  color: #ffffff;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 15.4px;
  position: absolute;
  top: 75px;
  white-space: nowrap;
  width: 850px;
}

.review-reqests .div-wrapperreqests {
  align-items: center;

  border-radius: 20px;
  display: flex;
  gap: 10px;
  height: 33px;
  justify-content: center;
  left: 23px;
  padding: 7px 16px;
  position: absolute;
  top: 121px;
}
.review-reqests .div-wrapperreqests1 {
  align-items: center;
  background-color: #60d48e;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  height: 33px;
  justify-content: center;
  left: 143px;
  padding: 7px 16px;
  position: absolute;
  top: 121px;
  width: 93px;
}
.review-reqests .divreqests {
  color: #ffffff;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.6px;
  margin-top: -1.5px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.review-reqests .card-2reqests {
  background: linear-gradient(
    180deg,
    rgba(223, 119, 98, 0.85) 0%,
    rgba(116, 180, 142, 0.85) 100%
  );
  border-radius: 24px;
  height: 130px;
  margin-right: -3px;
  position: relative;
  width: 933px;
}

.review-reqests .preqests {
  color: #ffffff;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  height: 18px;
  left: 43px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 29px;
  white-space: nowrap;
  width: 850px;
}

.review-reqests .text-wrapper-2reqests {
  color: #ffffff;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 43px;
  letter-spacing: 0;
  line-height: 19.6px;
  position: absolute;
  text-align: center;
  top: 86px;
  white-space: nowrap;
}

.review-reqests .checkreqests {
  height: 24px;
  left: 123px;
  object-fit: cover;
  position: absolute;
  top: 86px;
  width: 24px;
}

.review-reqests .frame-wrapperreqests {
  align-items: center;
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  height: 33px;
  justify-content: center;
  left: 159px;
  padding: 7px 16px;
  position: absolute;
  top: 81px;
  width: 93px;
}

.review-reqests .frame-2reqests {
  align-items: center;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  height: 33px;
  justify-content: center;
  margin-bottom: -7px;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -7px;
  padding: 7px 16px;
  position: relative;
  width: 93px;
}

.review-reqests .card-3reqests {
  background: linear-gradient(
    180deg,
    rgba(116, 180, 142, 0.85) 0%,
    rgba(223, 119, 98, 0.85) 100%,
    rgba(223, 119, 98, 0.85) 100%
  );
  border-radius: 24px;
  height: 130px;
  margin-right: -3px;
  position: relative;
  width: 933px;
}

.review-reqests .frame-3reqests {
  align-items: center;

  background-position: 50% 50%;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  height: 33px;
  justify-content: center;
  margin-bottom: -7px;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -7px;
  padding: 7px 16px;
  position: relative;
  width: 93px;
}

.review-reqests .card-4reqests {
  background: linear-gradient(
    180deg,
    rgba(116, 180, 142, 0.85) 0%,
    rgba(113, 157, 214, 0.85) 100%
  );
  border-radius: 24px;
  height: 130px;
  margin-right: -3px;
  position: relative;
  width: 933px;
}

.review-reqests .frame-4reqests {
  align-items: center;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  height: 33px;
  justify-content: center;
  margin-bottom: -7px;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -7px;
  padding: 7px 16px;
  position: relative;
  width: 93px;
}

.review-reqests .frame-5reqests {
  align-items: center;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  height: 33px;
  justify-content: center;
  margin-bottom: -7px;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -7px;
  padding: 7px 16px;
  position: relative;
  width: 93px;
}

.review-reqests .frame-6reqests {
  align-items: center;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  height: 33px;
  justify-content: center;
  margin-bottom: -7px;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -7px;
  padding: 7px 16px;
  position: relative;
  width: 93px;
}

.review-reqests .frame-7reqests {
  align-items: center;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  height: 33px;
  justify-content: center;
  margin-bottom: -7px;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -7px;
  padding: 7px 16px;
  position: relative;
  width: 93px;
}

.review-reqests .text-wrapper-3reqests {
  color: #264e65;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 22px;
  font-weight: 700;
  left: 331px;
  letter-spacing: 0;
  line-height: 30.8px;
  position: absolute;
  top: 42px;
  white-space: nowrap;
  width: 269px;
}
/* Adaugă aceste stiluri în response.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
}

.modal-image {
  max-width: 100%;
  max-height: 80vh;
  margin-bottom: 20px;
}

.modal-close {
  background: #333;
  color: #fff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
}

.review-reqests .frame-8reqests {
  align-items: center;
  background-color: #ffffff;
  border-radius: 22px;
  display: flex;
  gap: 8px;
  left: 334px;
  padding: 5px 20px;
  position: absolute;
  top: 92px;
  width: 504px;
}

.review-reqests .icon-searchreqests {
  height: 20px;
  position: relative;
  width: 20px;
}

.review-reqests .overlap-groupreqests {
  height: 19px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 19px;
}

.review-reqests .subtractreqests {
  height: 17px;
  left: 0;
  position: absolute;
  top: 0;
  width: 17px;
}

.review-reqests .vectorreqests {
  height: 5px;
  left: 13px;
  position: absolute;
  top: 13px;
  width: 5px;
}

.review-reqests .text-wrapper-4reqests {
  color: #959aa5;
  font-family: var(--p-14-font-family);
  font-size: var(--p-14-font-size);
  font-style: var(--p-14-font-style);
  font-weight: var(--p-14-font-weight);
  letter-spacing: var(--p-14-letter-spacing);
  line-height: var(--p-14-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.review-reqests .overlap-wrapperreqests {
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 24px;
  height: 700px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 272px;
}

.review-reqests .overlapreqests {
  background-size: 100% 100%;
  height: 700px;
  position: relative;
}

.review-reqests .menureqests {
  align-items: center;
  display: flex;
  gap: 8px;
  left: 72px;
  position: absolute;
  top: 188px;
  width: 200px;
}

.review-reqests .homereqests {
  height: 22px;
  object-fit: cover;
  position: relative;
  width: 22px;
}

.review-reqests .text-wrapper-5reqests {
  color: #ffffff;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.review-reqests .menu-2reqests {
  align-items: center;
  display: flex;
  gap: 8px;
  left: 72px;
  position: absolute;
  top: 252px;
  width: 150px;
}

.review-reqests .imgreqests {
  height: 24px;
  object-fit: cover;
  position: relative;
  width: 24px;
}

.review-reqests .text-wrapper-6reqests {
  color: #ffffff;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.6px;
  margin-right: -4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.review-reqests .menu-3reqests {
  align-items: center;
  display: flex;
  gap: 8px;
  left: 72px;
  position: absolute;
  top: 318px;
  width: 177px;
}

.review-reqests .text-wrapper-7reqests {
  color: #242730;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.review-reqests .rectanglereqests {
  background-color: #ffffff33;
  border-radius: 2px;
  height: 1px;
  left: 72px;
  position: absolute;
  top: 384px;
  width: 130px;
}

@media (min-width: 1481px) and (max-width: 1580px) {
  /* Further adjustments for even smaller screens */

  .review-reqests .cardreqests {
    width: 70%;
  }
  .review-reqests .miu-adrianreqests {
    font-size: 20px;
  }
  .review-reqests .frame-8reqests {
    width: 70%;
  }
}

@media (min-width: 1381px) and (max-width: 1480px) {
  .review-reqests .cardreqests {
    width: 70%;
  }
  .review-reqests .miu-adrianreqests {
    font-size: 20px;
  }
  .review-reqests .frame-8reqests {
    width: 70%;
  }
}

@media (max-width: 1281px) and (max-width: 1380px) {
  .review-reqests .cardreqests {
    width: 60%;
  }
  .review-reqests .miu-adrianreqests {
    font-size: 14px;
  }
  .review-reqests .frame-8reqests {
    width: 70%;
  }
}
@media (max-width: 1181px) and (max-width: 1280px) {
  .review-reqests .cardreqests {
    width: 50%;
  }
  .review-reqests .miu-adrianreqests {
    font-size: 15px;
  }
  .review-reqests .frame-8reqests {
    width: 50%;
  }
}
