.cardquiz {
  background: linear-gradient(
    180deg,
    rgba(113, 157, 214, 0.85) 0%,
    rgba(116, 180, 142, 0.85) 100%
  );
  background-color: rgba(255, 255, 255, 1);
  border-radius: 24px;
  height: 453px;
  position: relative;
  width: 992px;
  left: 301px;
}

.cardquiz .text-wrapper {
  font-size: 24px;
  font-weight: 500;
  height: 35px;
  left: 37px;
  letter-spacing: 0;
  line-height: 33.6px;
  position: absolute;
  top: 40px;
  white-space: nowrap;
  width: 344px;
}
.cardquiz .text-wrapperx {
  font-size: 22px;
  font-weight: 500;
  height: 35px;
  left: 37px;
  letter-spacing: 0;
  line-height: 33.6px;
  position: absolute;
  top: 410px;
  white-space: nowrap;
  width: 344px;
}
.cardquiz .text-wrapperx1 {
  font-size: 102px;
  font-weight: 500;
  height: 35px;
  left: 35%;
  letter-spacing: 0;
  line-height: 33.6px;
  position: absolute;
  top: 210px;
  white-space: nowrap;
  width: 344px;
}
.cardquiz .text-wrapperx2 {
  font-size: 52px;
  font-weight: 500;
  height: 35px;
  left: 30%;
  letter-spacing: 0;
  line-height: 33.6px;
  position: absolute;
  top: 110px;
  white-space: nowrap;
  width: 344px;
}

.cardquiz .div {
  color: #ffffff;
  font-size: 24px;
  font-weight: 500;
  height: 35px;
  left: 37px;
  letter-spacing: 0;
  line-height: 33.6px;
  position: absolute;
  top: 90px;
  white-space: nowrap;
  width: 872px;
}

.cardquiz .text-wrapper-2 {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  left: 72px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: 131px;
  width: 425px;
}

.cardquiz .text-wrapper-3 {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  left: 36px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: 368px;
  width: 157px;
}

.cardquiz .p {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  left: 72px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: 176px;
  width: 755px;
}

.cardquiz .text-wrapper-4 {
  color: #ffffff;
  font-weight: 400;
  left: 72px;
  letter-spacing: 0;
  line-height: 21px;
  width: fit-content;
  top: 174px;
  white-space: pre-wrap;
}

.cardquiz .text-wrapper-5 {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  left: 72px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: 267px;
  width: 755px;
}

.cardquiz .text-wrapper-6 {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  left: 72px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: 302px;
  width: 755px;
}

.cardquiz .ellipse {
  border-radius: 6px;
  height: 12px;
  left: 48px;
  position: absolute;
  top: 182px;
  width: 12px;
}

.btx {
  background: linear-gradient(
    90deg,
    rgba(108, 168, 151, 1) 0%,
    rgba(93, 140, 166, 1) 100%
  );
  color: #ffffff;
  border-radius: 20px;
}
