.e-mployed-sign-up {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.e-mployed-sign-up .signup-wrapper {
  background-position: 50% 50%;
  background-size: cover;
  height: 775px;
  width: 1600px;
}

.e-mployed-sign-up .signup {
  -webkit-backdrop-filter: blur(60px) brightness(100%);
  backdrop-filter: blur(60px) brightness(100%);
  background: linear-gradient(
    180deg,
    rgba(113, 157, 214, 0.4) 0%,
    rgba(223, 119, 98, 0.36) 100%
  );
  border-radius: 24px;
  box-shadow: 0px 10px 100px #3e266526;
  height: 700px;
  left: 82px;
  position: relative;
  top: 11%;
  width: 1435px;
}

.e-mployed-sign-up .logo {
  height: 132px;
  left: 551px;
  position: absolute;
  top: 19px;
  width: 334px;
}

.e-mployed-sign-up .frame {
  background-color: #ffffff;
  border-radius: 22px;
  height: 33px;
  left: 541px;
  position: absolute;
  top: 185px;
  width: 529px;
}

.e-mployed-sign-up .div {
  background-color: #ffffff;
  border-radius: 22px;
  height: 33px;
  left: 541px;
  position: absolute;
  top: 240px;
  width: 529px;
}

.e-mployed-sign-up .frame-2 {
  background-color: #ffffff;
  border-radius: 22px;
  height: 33px;
  left: 541px;
  position: absolute;
  top: 299px;
  width: 529px;
}

.e-mployed-sign-up .text-wrapper {
  color: #264e65;
  font-size: 18.3px;
  font-weight: 500;
  left: 366px;
  letter-spacing: 0;
  line-height: 25.6px;
  position: absolute;
  top: 188px;
  width: 136px;
}

.e-mployed-sign-up .text-wrapper-2 {
  color: #264e65;
  font-size: 18.3px;
  font-weight: 500;
  left: 366px;
  letter-spacing: 0;
  line-height: 25.6px;
  position: absolute;
  top: 243px;
  width: 136px;
}

.e-mployed-sign-up .text-wrapper-3 {
  color: #264e65;
  font-size: 18.3px;
  font-weight: 500;
  left: 366px;
  letter-spacing: 0;
  line-height: 25.6px;
  position: absolute;
  top: 302px;
  width: 136px;
}

.e-mployed-sign-up .div-wrapper {
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(223, 119, 98, 0.36) 0%,
    rgba(116, 180, 142, 0.4) 100%
  );
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  height: 33px;
  justify-content: center;
  left: 541px;
  padding: 7px 16px;
  position: absolute;
  top: 355px;
  width: 161px;
  color: #ffffff;
}
