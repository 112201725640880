.courses .frame {
  background-color: #c1d7d8;
  align-items: flex-start;
  display: flex;
  gap: 63px;
  height: 272px;
  left: 331px;
  overflow: hidden;
  overflow-x: auto;
  padding: 2.52px;
  position: absolute;
  top: 80px;
  width: 1104px;
}

.courses .frame-1 {
  background-color: #c1d7d8;
  align-items: flex-start;
  display: flex;
  gap: 63px;
  height: 272px;
  left: 331px;
  overflow: hidden;
  overflow-x: auto;
  padding: 2.52px;
  position: absolute;
  top: 80px;
  width: 1104px;
}

.no-scrollbar {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.courses .card {
  border-radius: 20.16px;

  height: 252px;
  position: relative;
  width: 378px;

  opacity: 0.8;
  /* Opacitate inițială redusă */
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 1;
  /* Opacitate mărită la hover */
}

.card::before {
  content: ">>> Enroll to Course";
  position: absolute;
  top: 16px;
  /* Ajustați poziția verticală */
  right: 30px;
  /* Ajustați poziția orizontală */
  opacity: 0;
  visibility: hidden;
  color: #fff;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.card:hover::before {
  opacity: 1;
  visibility: visible;
}

.courses .card-continue {
  border-radius: 20.16px;

  height: 252px;
  position: relative;
  width: 378px;

  opacity: 0.8;
  /* Opacitate inițială redusă */
}

.card-continue:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 1;
  /* Opacitate mărită la hover */
}

.card-continue::before {
  content: ">>> Continue to Course";
  position: absolute;
  top: 16px;
  /* Ajustați poziția verticală */
  right: 30px;
  /* Ajustați poziția orizontală */
  opacity: 0;
  visibility: hidden;
  color: #fff;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.card-continue:hover::before {
  opacity: 1;
  visibility: visible;
}

.courses .div {
  height: 252px;
  left: -47px;
  position: relative;
  width: 425px;
}

.courses .rectangle {
  height: 230px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 22px;
  width: 176px;
}

.courses .text-wrapper-2 {
  color: #ffffff;

  font-size: 15.1px;
  font-weight: 900;

  left: 202px;
  text-wrap: wrap;

  letter-spacing: 0;
  line-height: 21.2px;

  top: 60px;
}

.courses .p1 {
  color: #ffffff;

  font-size: 16.2px;
  font-weight: 800;
  left: 211px;
  text-wrap: wrap;

  letter-spacing: 0;
  line-height: 15.9px;
  position: absolute;
  top: 70px;
  width: 137px;
}

.courses .p {
  color: #ffffff;

  font-size: 11.24px;
  font-weight: 400;
  left: 211px;
  text-wrap: wrap;

  letter-spacing: 0;
  line-height: 140%;
  /* 12.936px */
  position: absolute;
  top: 100px;
  width: 136.921px;
  height: 118.441px;
  flex-shrink: 0;
}

.courses .overlap-wrapper {
  border-radius: 20.16px;
  height: 252px;
  overflow: hidden;
  position: relative;
  width: 378px;
}

.courses .overlap {
  height: 252px;
  left: -7px;
  position: relative;
  width: 385px;
}

.courses .rectangle-2 {
  height: 237px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 15px;
  width: 202px;
}

.courses .component-instance {
  border-radius: 20.16px !important;
  height: 252px !important;
  left: 7px !important;
  position: absolute !important;
  top: 0 !important;
  width: 378px !important;
}

.courses .overlap-group-wrapper {
  background: linear-gradient(
    180deg,
    rgba(223, 119, 98, 0.62) 0%,
    rgba(113, 157, 214, 0.56) 100%
  );
  border-radius: 20.16px;
  height: 252px;
  margin-right: -2.52px;
  overflow: hidden;
  position: relative;
  width: 378px;
}

.courses .overlap-2 {
  border-radius: 20.16px;
  height: 252px;
  position: relative;
  width: 378px;
}

.courses .rectangle-3 {
  height: 176px;
  left: 9px;
  object-fit: cover;
  position: absolute;
  top: 38px;
  width: 180px;
}

.courses .text-wrapper-5 {
  color: #ffffff;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 15.1px;
  font-weight: 700;
  height: 21px;
  left: 195px;
  letter-spacing: 0;
  line-height: 21.2px;
  position: absolute;
  top: 60px;
  white-space: nowrap;
  width: 172px;
}

.courses .text-wrapper-6 {
  -webkit-text-stroke: 0.84px #000000;
  color: #ffffff;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 9.2px;
  font-weight: 400;
  left: 194px;
  letter-spacing: 0;
  line-height: 12.9px;
  position: absolute;
  top: 107px;
  width: 137px;
}

.courses .component-1-instance {
  border-radius: 20.16px !important;
  height: 252px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 378px !important;
}

.courses .div-wrapper {
  background: linear-gradient(
    180deg,
    rgba(116, 180, 142, 0.56) 0%,
    rgba(223, 119, 98, 0.62) 99%
  );
  border-radius: 20.16px;
  height: 252px;
  margin-right: -2.52px;
  overflow: hidden;
  position: relative;
  width: 378px;
}

.courses .rectangle-4 {
  height: 181px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 46px;
  width: 180px;
}

.courses .card-2 {
  background: linear-gradient(
    180deg,
    rgba(113, 157, 214, 0.85) 0%,
    rgba(116, 180, 142, 0.85) 100%
  );
  border-radius: 20.16px;
  height: 252px;
  margin-right: -2.52px;
  overflow: hidden;
  position: relative;
  width: 378px;
}

.courses .rectangle-5 {
  height: 176px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 55px;
  width: 180px;
}

.courses .card-3 {
  background: linear-gradient(
    180deg,
    rgba(223, 119, 98, 0.85) 0%,
    rgba(116, 180, 142, 0.85) 100%
  );
  border-radius: 20.16px;
  height: 252px;
  margin-right: -2.52px;
  overflow: hidden;
  position: relative;
  width: 378px;
}

.courses .overlap-3 {
  height: 270px;
  position: relative;
  width: 378px;
}

.courses .rectangle-6 {
  height: 220px;
  left: 13px;
  object-fit: cover;
  position: absolute;
  top: 50px;
  width: 155px;
}

.courses .card-4 {
  background: linear-gradient(
    180deg,
    rgba(116, 180, 142, 0.85) 0%,
    rgba(223, 119, 98, 0.85) 99%
  );
  border-radius: 20.16px;
  height: 252px;
  margin-right: -2.52px;
  overflow: hidden;
  position: relative;
  width: 378px;
}

.courses .overlap-4 {
  height: 252px;
  left: -6px;
  position: relative;
  width: 384px;
}

.courses .text-wrapper-7 {
  color: #ffffff;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 15.1px;
  font-weight: 700;
  height: 21px;
  left: 201px;
  letter-spacing: 0;
  line-height: 21.2px;
  position: absolute;
  top: 60px;
  white-space: nowrap;
  width: 172px;
}

.courses .text-wrapper-8 {
  -webkit-text-stroke: 0.84px #000000;
  color: #ffffff;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 9.2px;
  font-weight: 400;
  left: 200px;
  letter-spacing: 0;
  line-height: 12.9px;
  position: absolute;
  top: 107px;
  width: 137px;
}

.courses .rectangle-7 {
  height: 171px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 48px;
  width: 195px;
}

.courses .design-component-instance-node {
  border-radius: 20.16px !important;
  height: 252px !important;
  left: 6px !important;
  position: absolute !important;
  top: 0 !important;
  width: 378px !important;
}

.courses .card-5 {
  background: linear-gradient(
    180deg,
    rgba(223, 119, 98, 0.85) 0%,
    rgba(113, 157, 214, 0.85) 100%
  );
  border-radius: 20.16px;
  height: 252px;
  margin-right: -2.52px;
  overflow: hidden;
  position: relative;
  width: 378px;
}

.courses .rectangle-8 {
  height: 169px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  top: 45px;
  width: 178px;
}

.courses .rectangle-9 {
  height: 161px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 50px;
  width: 186px;
}

.courses .rectangle-10 {
  height: 191px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 47px;
  width: 188px;
}

.courses .frame-1 {
  align-items: flex-start;
  display: flex;
  gap: 63px;
  height: 272px;
  left: 331px;
  overflow: hidden;
  overflow-x: scroll;
  padding: 2.52px;
  position: absolute;
  top: 395px;
  width: 1104px;
}

.courses .frame-2 {
  align-items: flex-start;
  display: flex;
  gap: 63px;
  height: 272px;
  left: 331px;
  overflow: hidden;
  overflow-y: scroll;
  padding: 2.52px;
  position: absolute;
  top: 395px;
  width: 1104px;
}

.courses .text-wrapper-9 {
  color: #264e65;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 22px;
  font-weight: 700;
  left: 331px;
  letter-spacing: 0;
  line-height: 30.8px;
  position: absolute;
  top: 42px;
  white-space: nowrap;
  width: 269px;
}

.courses .text-wrapper-10 {
  color: #264e65;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 22px;
  font-weight: 700;
  left: 331px;
  letter-spacing: 0;
  line-height: 30.8px;
  position: absolute;
  top: 357px;
  white-space: nowrap;
  width: 269px;
}

.courses .frame-3 {
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 24px;
  height: 702px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 272px;
}

.courses .overlap-5 {
  background-size: 100% 100%;
  height: 701px;
  position: relative;
  top: 1px;
}

.courses .menu {
  align-items: center;
  display: flex;
  gap: 8px;
  left: 72px;
  position: absolute;
  top: 187px;
  width: 200px;
}

.courses .home {
  height: 22px;
  object-fit: cover;
  position: relative;
  width: 22px;
}

.courses .text-wrapper-11 {
  color: #ffffff;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.courses .menu-2 {
  align-items: center;
  display: flex;
  gap: 8px;
  left: 72px;
  position: absolute;
  top: 251px;
  width: 150px;
}

.courses .img-2 {
  height: 24px;
  object-fit: cover;
  position: relative;
  width: 24px;
}

.courses .text-wrapper-12 {
  color: #242730;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.6px;
  margin-right: -4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.courses .menu-3 {
  align-items: center;
  display: flex;
  gap: 8px;
  left: 72px;
  position: absolute;
  top: 317px;
  width: 177px;
}

.courses .rectangle-11 {
  background-color: #ffffff33;
  border-radius: 2px;
  height: 1px;
  left: 72px;
  position: absolute;
  top: 383px;
  width: 130px;
}

.courses .menu-4 {
  align-items: center;
  display: flex;
  gap: 8px;
  left: 72px;
  position: absolute;
  top: 424px;
  width: 162px;
}

.courses .menu-5 {
  align-items: center;
  display: flex;
  gap: 8px;
  left: 72px;
  position: absolute;
  top: 488px;
  width: 155px;
}

.courses .icon-log-out {
  height: 24px;
  position: relative;
  width: 24px;
}

.courses .subtract {
  height: 21px;
  left: 5px;
  position: absolute;
  top: 2px;
  width: 14px;
}

.courses .overlap-group-2 {
  height: 104px;
  left: 19px;
  position: absolute;
  top: 31px;
  width: 234px;
}

.courses .frame-4 {
  height: 87px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 234px;
}

.courses .logo {
  height: 104px;
  left: 4px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 227px;
}

@media (min-width: 1481px) and (max-width: 1580px) {
  /* Further adjustments for even smaller screens */

  .courses .frame {
    width: 70%;
  }
  .courses .frame-1 {
    width: 70%;
  }
}

@media (min-width: 1381px) and (max-width: 1480px) {
  .courses .frame {
    width: 70%;
  }
  .courses .frame-1 {
    width: 70%;
  }
}

@media (max-width: 1281px) and (max-width: 1380px) {
  .courses .frame {
    width: 60%;
  }
  .courses .frame-1 {
    width: 60%;
  }
}
