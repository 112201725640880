.e-mployed-course {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.e-mployed-course .frame {
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 24px;
  height: 700px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 272px;
}

.e-mployed-course .overlap-group {
  background-size: 100% 100%;
  height: 700px;
  position: relative;
}

.e-mployed-course .menu {
  align-items: center;
  display: flex;
  gap: 8px;
  left: 72px;
  position: absolute;
  top: 188px;
  width: 200px;
}

.e-mployed-course .text-wrapper {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  text-wrap: wrap;
}

.e-mployed-course .div {
  align-items: center;
  display: flex;
  gap: 8px;
  left: 72px;
  position: absolute;
  top: 252px;
  width: 150px;
}

.e-mployed-course .book {
  height: 24px;
  position: relative;
  width: 24px;
}

.e-mployed-course .text-wrapper-2 {
  color: #ffffff;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.6px;
  margin-right: -4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  text-wrap: wrap;
}

.e-mployed-course .rectangle {
  background-color: #ffffff33;
  border-radius: 2px;
  height: 1px;
  left: 72px;
  position: absolute;
  top: 445px;
  width: 130px;
}

.e-mployed-course .subtract {
  height: 21px;
  left: 5px;
  position: absolute;
  top: 2px;
  width: 14px;
}

.e-mployed-course .overlap {
  height: 104px;
  left: 19px;
  position: absolute;
  top: 32px;
  width: 234px;
}

.e-mployed-course .img {
  height: 87px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 234px;
}

.e-mployed-course .cardff {
  background: linear-gradient(
    180deg,
    rgba(113, 157, 214, 0.85) 0%,
    rgba(116, 180, 142, 0.85) 100%
  );
  border-radius: 24px;
  height: 364px;
  left: 331px;
  position: absolute;
  top: 38px;
  width: 772px;
}

.e-mployed-course .overlap-2 {
  height: 349px;
  left: 23px;
  position: relative;
  top: 15px;
  width: 755px;
}

.e-mployed-course .rectangle-2 {
  height: 369px;

  width: 359px;
}
.framef {
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(108, 168, 151, 1) 0%,
    rgba(93, 140, 166, 1) 100%
  );
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  height: 53px;
  justify-content: center;
  padding: 7px 16px;
  position: relative;
  width: 180px;
  left: 450px;
  margin-top: -60px;
}

.framef .group {
  height: 18.5px;

  width: 27.25px;
}

.framef .text-wrapper {
  color: #ffffff;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.6px;
  margin-top: -1.5px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.e-mployed-course .text-wrapper-3 {
  color: #ffffff;
  font-size: 36px;
  font-weight: 700;
  height: 35px;
  left: 400px;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
  width: 344px;
  text-wrap: wrap;
}

.e-mployed-course .p {
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  left: 400px;
  line-height: 15.4px;
  letter-spacing: 0;
  position: absolute;
  top: 80px;
  width: 273px;
}

.e-mployed-course .frame-2 {
  align-items: center;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  height: 33px;
  justify-content: center;
  left: 411px;
  padding: 7px 16px;
  position: absolute;
  top: 299px;
  width: 180px;
}

.e-mployed-course .group {
  height: 18.5px;
  position: relative;
  width: 27.25px;
}

.e-mployed-course .text-wrapper-4 {
  color: #ffffff;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.6px;
  margin-top: -1.5px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.e-mployed-course .text-wrapper-5 {
  color: #24262f;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 22px;
  font-weight: 700;
  left: 331px;
  letter-spacing: 0;
  line-height: 30.8px;
  position: absolute;
  top: 449px;
  width: 190px;
}

.e-mployed-course .frame-3 {
  align-items: flex-start;
  border-radius: 24px;
  display: flex;
  gap: 33px;
  height: 180px;
  left: 312px;
  overflow: hidden;
  overflow-x: scroll;
  padding: 0px 33px 0px 20px;
  position: absolute;
  top: 493px;
  width: 1088px;
}

.e-mployed-course .overlap-group-wrapper {
  background: linear-gradient(
    180deg,
    rgb(113, 157, 214) 0%,
    rgba(116, 180, 142, 0.5) 100%
  );
  border-radius: 24px;
  height: 180px;
  position: relative;
  width: 240px;
}

.e-mployed-course .overlap-group-2 {
  height: 180px;
  position: relative;
}

.e-mployed-course .div-wrapper {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  left: 0;
  padding: 8px 16px 16px;
  position: absolute;
  top: 0;
  width: 240px;
}

.e-mployed-course .text-wrapper-6 {
  color: #ffffff;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  height: 25px;
  letter-spacing: 0;
  line-height: 25.2px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 205px;
}

.e-mployed-course .rectangle-3 {
  height: 160px;
  left: 34px;
  object-fit: cover;
  position: absolute;
  top: 20px;
  width: 171px;
}

.e-mployed-course .overlap-wrapper {
  background: linear-gradient(
    180deg,
    rgb(223, 119, 98) 0%,
    rgba(113, 157, 214, 0.52) 100%
  );
  border-radius: 24px;
  height: 180px;
  position: relative;
  width: 240px;
}

.e-mployed-course .rectangle-4 {
  height: 180px;
  left: 30px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 191px;
}

.e-mployed-course .card-2 {
  background: linear-gradient(
    180deg,
    rgb(116, 180, 142) 0%,
    rgba(113, 157, 214, 0.5) 100%
  );
  border-radius: 24px;
  height: 180px;
  position: relative;
  width: 240px;
}

.e-mployed-course .card-3 {
  background: linear-gradient(
    180deg,
    rgba(116, 180, 142, 0.62) 0%,
    rgba(223, 119, 98, 0.56) 100%
  );
  border-radius: 24px;
  height: 180px;
  position: relative;
  width: 240px;
}

.e-mployed-course .rectangle-5 {
  height: 147px;
  left: 42px;
  object-fit: cover;
  position: absolute;
  top: 33px;
  width: 155px;
}

.e-mployed-course .rectangle-6 {
  height: 141px;
  left: 24px;
  object-fit: cover;
  position: absolute;
  top: 39px;
  width: 175px;
}

.e-mployed-course .card-4 {
  background: linear-gradient(
    180deg,
    rgba(223, 119, 98, 0.62) 0%,
    rgba(116, 180, 142, 0.56) 100%
  );
  border-radius: 24px;
  height: 180px;
  position: relative;
  width: 240px;
}

.e-mployed-course .rectangle-7 {
  height: 167px;
  left: 30px;
  object-fit: cover;
  position: absolute;
  top: 13px;
  width: 177px;
}

.e-mployed-course .card-5 {
  background: linear-gradient(
    180deg,
    rgb(223, 119, 98) 0%,
    rgba(113, 157, 214, 0.52) 100%
  );
  border-radius: 24px;
  box-shadow: var(--shadow-violet);
  height: 180px;
  position: relative;
  width: 240px;
}

.e-mployed-course .rectangle-8 {
  height: 172px;
  left: 28px;
  object-fit: cover;
  position: absolute;
  top: 8px;
  width: 181px;
}

.e-mployed-course .rectangle-9 {
  height: 147px;
  left: 31px;
  object-fit: cover;
  position: absolute;
  top: 33px;
  width: 178px;
}

.cframe-1 {
  align-items: flex-start;
  display: flex;
  gap: 63px;
  height: 222px;
  left: 331px;
  overflow: hidden;
  overflow-x: auto;
  padding: 2.52px;
  position: absolute;
  top: 69%;
  width: 1104px;
}
.cno-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.ccard {
  background: linear-gradient(
    180deg,
    rgba(223, 119, 98, 0.85) 0%,
    rgba(116, 180, 142, 0.85) 100%
  );
  border-radius: 20.16px;
  height: 202px;
  position: relative;
  width: 308px;

  opacity: 0.8; /* Opacitate inițială redusă */
}
.ccard:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 1; /* Opacitate mărită la hover */
}
.ccard::before {
  content: ">>> Enroll Now";
  position: absolute;
  top: 6px; /* Ajustați poziția verticală */
  right: 30px; /* Ajustați poziția orizontală */
  opacity: 0;
  visibility: hidden;
  color: #fff;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.ccard:hover::before {
  opacity: 1;
  visibility: visible;
}
.rectanglec {
  height: 200px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 12px;
  width: 126px;
}

.cp1 {
  color: #ffffff;

  font-size: 17.2px;
  font-weight: 800;
  left: 150px;
  text-wrap: wrap;

  letter-spacing: 0;
  line-height: 15.9px;
  position: absolute;
  top: 57px;
  width: 137px;
}
.cp {
  color: #ffffff;

  font-size: 14.5px;
  font-weight: 400;
  left: 150px;
  text-wrap: wrap;

  letter-spacing: 0;
  line-height: 14.9px;
  position: absolute;
  top: 97px;
  width: 137px;
}

@media (min-width: 1381px) and (max-width: 1480px) {
  .cframe-1 {
    width: 70%;
  }
}

@media (max-width: 1281px) and (max-width: 1380px) {
  .cframe-1 {
    width: 70%;
  }
}
@media (max-width: 1181px) and (max-width: 1280px) {
  .cframe-1 {
    width: 60%;
  }
}
