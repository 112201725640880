.signup {
  background: linear-gradient(
    180deg,
    rgba(223, 119, 98, 0.36) 0%,
    rgba(116, 180, 142, 0.4) 100%
  );
  background-color: rgba(255, 255, 255, 1);
  border-radius: 24px;
  height: 700px;
  position: relative;
  width: 1435px;
  left: 10%;
  top: 10%;
}

.signup .logo {
  height: 132px;
  left: 551px;
  position: absolute;
  top: 9px;
  width: 334px;
}

.signup .frame {
  background-color: #ffffff;
  border-radius: 22px;
  height: 33px;
  left: 541px;
  position: absolute;
  top: 287px;
  width: 529px;
}

.signup .overlap-group {
  background-color: #ffffff;
  border-radius: 22px;
  height: 33px;
  left: 541px;
  position: absolute;
  top: 342px;
  width: 529px;
}

.signup .div {
  background-color: #ffffff;
  border-radius: 22px;
  height: 33px;
}

.signup .text-wrapper {
  color: #264e65;
  font-size: 18.3px;
  font-weight: 500;
  left: 366px;
  letter-spacing: 0;
  line-height: 25.6px;
  position: absolute;
  top: 290px;
  width: 136px;
}

.signup .text-wrapper-2 {
  color: #264e65;
  font-size: 18.3px;
  font-weight: 500;
  left: 366px;
  letter-spacing: 0;
  line-height: 25.6px;
  position: absolute;
  top: 345px;
  width: 136px;
}

.signup .text-wrapper-3 {
  color: #264e65;
  font-size: 18.3px;
  font-weight: 500;
  left: 366px;
  letter-spacing: 0;
  line-height: 25.6px;
  position: absolute;
  top: 455px;
  width: 152px;
}

.signup .text-wrapper-4 {
  color: #264e65;
  font-size: 18.3px;
  font-weight: 500;
  left: 366px;
  letter-spacing: 0;
  line-height: 25.6px;
  position: absolute;
  top: 513px;
  width: 136px;
}

.signup .text-wrapper-5 {
  color: #264e65;
  font-size: 18.3px;
  font-weight: 500;
  left: 366px;
  letter-spacing: 0;
  line-height: 25.6px;
  position: absolute;
  top: 571px;
  width: 136px;
}

.signup .div-wrapper {
  background: linear-gradient(
    180deg,
    rgba(223, 119, 98, 0.36) 0%,
    rgba(116, 180, 142, 0.4) 100%
  );
  align-items: center;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  height: 33px;
  justify-content: center;
  left: 541px;
  padding: 7px 16px;
  position: absolute;
  top: 397px;
  width: 205px;
}

.signup .text-wrapper-6 {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.6px;
  margin-top: -1.5px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.signup .frame-2 {
  background: linear-gradient(
    180deg,
    rgba(223, 119, 98, 0.36) 0%,
    rgba(116, 180, 142, 0.4) 100%
  );
  align-items: center;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  height: 33px;
  justify-content: center;
  left: 541px;
  padding: 7px 16px;
  position: absolute;
  top: 453px;
  width: 50px;
}

.signup .text-wrapper-7 {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.6px;
  margin-left: -3.5px;
  margin-right: -3.5px;
  margin-top: -1.5px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.signup .frame-3 {
  background: linear-gradient(
    180deg,
    rgba(223, 119, 98, 0.36) 0%,
    rgba(116, 180, 142, 0.4) 100%
  );
  align-items: center;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  height: 33px;
  justify-content: center;
  left: 795px;
  padding: 7px 16px;
  position: absolute;
  top: 397px;
  width: 205px;
}

.signup .p {
  color: #264e65;
  font-size: 22px;
  font-weight: 700;
  left: 583px;
  letter-spacing: 0;
  line-height: 30.8px;
  position: absolute;
  top: 175px;
  white-space: nowrap;
  width: 269px;
}
.signup .p2 {
  color: red;
  font-size: 22px;
  font-weight: 700;
  left: 583px;
  letter-spacing: 0;
  line-height: 30.8px;
  position: absolute;
  top: 640px;
  white-space: nowrap;
  width: 269px;
}
.signup .or-register {
  color: transparent;
  font-size: 22px;
  font-weight: 400;
  left: 583px;
  letter-spacing: 0;
  line-height: 30.8px;
  position: absolute;
  top: 216px;
  white-space: nowrap;
  width: 136px;
}

.signup .span {
  color: #264e65;
  font-weight: 700;
}

.signup .text-wrapper-8 {
  color: #216fcb;
  font-family: "Poppins-SemiBoldItalic", Helvetica;
  font-style: italic;
  font-weight: 600;
}

.signup .github {
  height: 47px;
  left: 757px;
  position: absolute;
  top: 504px;
  width: 47px;
}

.signup .mail {
  height: 50px;
  left: 613px;
  position: absolute;
  top: 502px;
  width: 50px;
}

.signup .facebook {
  height: 50px;
  left: 541px;
  object-fit: cover;
  position: absolute;
  top: 502px;
  width: 50px;
}

.signup .twitter {
  height: 50px;
  left: 685px;
  object-fit: cover;
  position: absolute;
  top: 502px;
  width: 50px;
}

.signup .flag-of-the-united {
  height: 25px;
  left: 541px;
  object-fit: cover;
  position: absolute;
  top: 573px;
  width: 50px;
}
