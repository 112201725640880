.profile {
  border-radius: 24px;
  height: 700px;
  position: relative;
  width: 1435px;
}

.profile .text-wrapper {
  color: #264e65;
  font-family: "Poppins-Bold", Helvetica;
  font-size: 22px;
  font-weight: 700;
  left: 331px;
  letter-spacing: 0;
  line-height: 30.8px;
  position: absolute;
  top: 42px;
  white-space: nowrap;
  width: 269px;
}

.profile .text-wrapper-8 {
  color: #264e65;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 18.3px;
  font-weight: 500;
  left: 332px;
  letter-spacing: 0;
  line-height: 25.6px;
  position: absolute;
  top: 276px;
  width: 136px;
}

.profile .frame {
  align-items: center;
  background-color: #ffffff;
  border-radius: 22px;
  display: flex;
  gap: 8px;
  height: 33px;
  left: 507px;
  padding: 10px 20px;
  position: absolute;
  top: 141px;
  width: 203px;
}

.profile .overlap {
  height: 41px;
  left: 507px;
  position: absolute;
  top: 405px;
  width: 529px;
  z-index: 150;
}

.profile .div-wrapper {
  align-items: center;
  background-color: #ffffff;
  border-radius: 22px;
  display: flex;
  gap: 8px;

  left: 0;
  padding: 10px 20px;
  position: absolute;
  top: 4px;
  width: 529px;
}

.profile .give-your {
  margin-left: 200px;
  font-size: 12px;
}

.profile .text-wrapper-2 {
  color: #242730;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 18.3px;
  font-weight: 500;
  height: 26px;
  letter-spacing: 0;
  line-height: 25.6px;
  margin-bottom: -5.5px;
  margin-top: -7.5px;
  position: relative;
  white-space: nowrap;
  width: 455px;
}

.profile .overlap-group {
  background: none;
  height: 41px;
  left: 507px;
  position: absolute;
  top: 460px;
  width: 529px;
}

.profile .overlap-2 {
  height: 41px;
  left: 507px;
  position: absolute;
  top: 519px;
  width: 529px;
}

.profile .p {
  color: #959ba5;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 18.3px;
  font-weight: 500;
  left: 332px;
  letter-spacing: 0;
  line-height: 25.6px;
  position: absolute;
  top: 88px;
  width: 704px;
}

.profile .overlap,
.profile .overlap-group,
.profile .overlap-2 {
  z-index: 100;
  /* Adjust the value as needed */
}

input {
  border: 0px;
  border-color: none;
  background: none;
}

.profile .text-wrapper-3 {
  color: #264e65;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 18.3px;
  font-weight: 500;
  left: 332px;
  letter-spacing: 0;
  line-height: 25.6px;
  position: absolute;
  top: 144px;
  width: 136px;
}

.profile .text-wrapper-4 {
  color: #264e65;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 18.3px;
  font-weight: 500;
  left: 320px;
  letter-spacing: 0;
  line-height: 25.6px;
  position: absolute;
  top: 425px;
  width: 136px;
}

.profile .text-wrapper-5 {
  color: #264e65;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 18.3px;
  font-weight: 500;
  left: 358px;
  letter-spacing: 0;
  line-height: 25.6px;
  position: absolute;
  top: 475px;
  width: 136px;
}

.profile .text-wrapper-6 {
  color: #264e65;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 18.3px;
  font-weight: 500;
  left: 332px;
  letter-spacing: 0;
  line-height: 25.6px;
  position: absolute;
  top: 540px;
  width: 136px;
}

.profile .text-wrapper-7 {
  color: #264e65;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 18.3px;
  font-weight: 500;
  left: 332px;
  letter-spacing: 0;
  line-height: 25.6px;
  position: absolute;
  top: 582px;
  width: 136px;
}

.profile .avatar {
  background-position: 50% 50%;
  background-size: cover;
  border: 5.23px solid;
  border-color: #ffffff;
  border-radius: 65.35px;
  height: 189px;
  left: 502px;
  position: absolute;
  top: 195px;
  width: 189px;
}

.profile .frame-2 {
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(108, 168, 151, 1) 0%,
    rgba(93, 140, 166, 1) 100%
  );
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  height: 40px;
  justify-content: center;
  left: 491px;
  position: absolute;
  top: 579px;
  width: 161px;
}
.profile .frame-vr {
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(108, 168, 151, 1) 0%,
    rgba(93, 140, 166, 1) 100%
  );
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  height: 57px;
  justify-content: center;
  left: 731px;
  position: absolute;
  top: 256px;
  width: 161px;
}
.profile .text-wrapper-9-vr {
  color: #ffffff;
}

.profile .text-wrapper-9 {
  color: #ffffff;
  font-size: 15px;
  margin-bottom: -2.41px;
}

.profile .frame-3 {
  align-items: center;
  height: 40px;
  background: linear-gradient(
    90deg,
    rgba(108, 168, 151, 1) 0%,
    rgba(93, 140, 166, 1) 100%
  );
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 20px;
  display: flex;

  justify-content: center;
  left: 691px;
  position: absolute;
  top: 579px;
  width: 161px;
}

.profile .frame-5 {
  align-items: center;
  background-color: #60d48e;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  height: 33px;
  justify-content: center;
  left: 442px;
  padding: 7px 16px;
  position: absolute;
  top: 647px;
  width: 93px;
}

.profile .frame-6 {
  align-items: center;
  background-color: #e0998a;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  height: 33px;
  justify-content: center;
  left: 331px;
  padding: 7px 6px;
  position: absolute;
  top: 647px;
  width: 93px;
}

.profile .frame-7 {
  align-items: center;
  background-color: transparent;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  height: 33px;
  justify-content: center;
  left: 550px;
  padding: 7px 6px;
  position: absolute;
  top: 647px;
  width: 500px;
}

@media (min-width: 1481px) and (max-width: 1580px) {
  /* Further adjustments for even smaller screens */

  .profile .div-wrapper {
    width: 70%;
  }
}

@media (min-width: 1381px) and (max-width: 1480px) {
  .profile .div-wrapper {
    width: 70%;
  }
}

@media (max-width: 1281px) and (max-width: 1380px) {
  .profile .div-wrapper {
    width: 70%;
  }
}
@media (max-width: 1181px) and (max-width: 1280px) {
  .profile .div-wrapper {
    width: 70%;
  }
}
